import React, { Fragment, useEffect, useState} from "react";
import { Link } from "gatsby";
import CalendlyButtonLink from "@components/book-video-call/";

// animations
import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollToPlugin);

// https://stackoverflow.com/questions/646628/how-to-check-if-a-string-startswith-another-string
// the problem with using indexOf is that if it fails matching at the start, it will continue searching
// the entire string, whereby lastIndexOf starts from the length of the word and walks back to zero.
function startsWith(str, word) {
    return str.lastIndexOf(word, 0) === 0;
}

const ContentHeaderImageBtns2 = React.forwardRef((props, ref) => {

    const [scrollAnimation, setScrollAnimation] = useState(false);


    return(
        <>
        
        <div className="section-content-header-image__cta" ref={ref}>
            {props.ButtonData && props.ButtonData.map((btn, index) => {
                if (btn.page) { // if its a normal internal link
                    return(
                        <Fragment key={index}>
                            <Link to={`/${btn.page.Slug}/`} className={props.btnClass} state={btn.page.btnState ? btn.page.btnState : null}>
                                {btn.Label}
                            </Link>
                        </Fragment>
                    )
                } else if (btn.targetLink) { // using href target link to scroll to ID, or it's a relative URL
                    const isTarget = startsWith(btn.targetLink, '#');
                    const isTargetLength = btn.targetLink.length > 1; // sometimes a link can have a dummy placeholder '#', so we want to exclude it as it's not a valid targer
                    if (isTarget && isTargetLength) {
                        useEffect(() => {
                            setScrollAnimation(
                                gsap.to(window, {
                                    duration: 1,
                                    scrollTo: {y: btn.targetLink},
                                    ease: animSetting.ease
                                }).pause()
                            );
                        }, [btn.targetLink])
                    }
                    return(
                        <Fragment key={index}>
                            <a
                                href={`${btn.targetLink}/`}
                                className={props.btnClass}
                                onClick={isTarget && isTargetLength ? (e) => {e.preventDefault(); scrollAnimation.restart()} : null }
                            >
                                {btn.Label}
                            </a>
                        </Fragment>
                    )
                } else if (btn.externalLink) { // using external link
                    const isCalendly = startsWith(btn.externalLink, 'https://calendly.com');
                    if (isCalendly) {
                        return(
                            <CalendlyButtonLink
                                label={btn.Label}
                                url={btn.externalLink}
                                btnClass={props.btnClass}
                            />
                        )
                    } else {
                        return(
                            <Fragment key={index}>
                                <a href={`${btn.externalLink}/`} className={props.btnClass} target="_blank" rel="noreferrer">
                                    {btn.Label}
                                </a>
                            </Fragment>
                        )
                    }
                } else {
                    return null
                }
            })}
        </div>
        
        </>

    )
});

export default ContentHeaderImageBtns2