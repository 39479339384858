import React from "react";
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";

import "@assets/scss/App.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import BackToolbar from "@components/modules/back-toolbar/";
// import UploadCv from "@components/forms/send-cv/";

import ImageTextModule from "@components/modules/image-text/";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

const UploadCVPage = (props) => {
    const imgUrl = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "Candidate-Refer_a_Candidate-Tile.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    return(
        <Layout>
            <SEO
                title="Upload your CV | Prop Tech and Estate Agent Jobs"
                // description="If you know of someone looking for PropTech & estate agents jobs in the UK, why not refer them to us? Crux Careers is a leader in PropTech & estate agency recruitment."
            />
            <Helmet 
                bodyAttributes={{
                    class: 'grey-bg'
                }}
            />
            <Header />
            <BackToolbar
                backLinkLabel="Back"
                showCreateAlert={false}
            />
            <h1 style={{display:'none'}}>Prop Tech and Estate Agent Jobs</h1>
            {/* <UploadCv
                title="Upload your CV for PropTech and Estate Agent Jobs"
                intro="If you’re excited by PropTech and estate agency recruitment, have a great work ethic and genuinely care about people, why not submit your CV today?"
                formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
            /> */}
            <div className="iframe-blk">
            <iframe style={{height:'1300px',width:'100%', maxWidth:'90%', maxHeight :'90%', border:'none', margin:'auto', textAlign:'center', display:'block'}} src="https://recruitcrm.io/talent_pool_submission/Crux_Careers_1"> </iframe>
            </div>
            <ImageTextModule
                Title="Refer a Candidate for Prop Tech and Estate Agent Jobs"
                imgUrl={imgUrl.file.childImageSharp.fluid}
                moduleData={
                    [
                        {
                            id: 1,
                            imageUrl: 'young-woman.jpg',
                            videoUrl: '',
                            Title: '',
                            Text: '',
                            Buttons: [
                                {
                                    id: '123213',
                                    Label: 'Refer a Candidate',
                                    externalLink: null,
                                    page: null,
                                    targetLink: '/candidates/refer-a-candidate'
                                }
                            ]
                        }
                    ]
                }
            />
            <PopularSearch {...props}
                sectionClass=" section-grey-bg"
            />
            <Footer />
        </Layout>
    )
}

export default UploadCVPage
