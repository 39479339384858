import React from 'react';

import ContentHeaderImageBtns from "@components/modules/content-header-image/_content-header-image-btns";

const Text = React.forwardRef((props, ref) => {
    return (
        <div className={`section-image-text__text${props.textClass}`} ref={ref}>
            <h2 className={props.titleClass}>{props.Title}</h2>
            {props.moduleData.map((item, index) => (
                <ContentHeaderImageBtns
                    key={index}
                    btnContainerClass=""
                    btnClass={props.btnClass}
                    ButtonData={item.Buttons}
                />
            ))}
        </div>
    );
})

export default Text;