import React from 'react';
import BackgroundImage from 'gatsby-background-image';

const Image = React.forwardRef((props, ref) => {
    return (
        <BackgroundImage
            Tag="div"
            className={`image-text-module__image`}
            fluid={props.imgUrl}
            // preserveStackingContext={true}
            critical={true}
            role="img"
            ref={ref}
        >
        </BackgroundImage>
    );
})

export default Image;