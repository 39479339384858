import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import  "./assets/_index.scss";

import Image from "./Image";
import Text from "./Text";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const ImageTextModule = (props) => {
    let contAnim = useRef(null);
    let imgAnim = useRef(null);
    let textAnim = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.fromTo(imgAnim.current.selfRef, {
            opacity: 0,
            x: -200
        },{
            opacity: 1,
            x: 0,
            duration: 1.2
        }).fromTo(textAnim.current, {
            opacity: 0,
            x: 100
        },{
            opacity: 1,
            x: 0
        }, "-=0.5")
    }, [])

    const imgUrl = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "young-woman.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <section className={`section section-image-text${props.sectionClass}`} ref={el => contAnim = el}>
            <div className={props.moduleClass}>
                <Image
                    imageClass={props.imageClass}
                    imgUrl={props.imgUrl ? props.imgUrl : imgUrl.file.childImageSharp.fluid}
                    ref={imgAnim}
                />
                <Text
                    textClass={props.textClass}
                    Title={props.Title}
                    titleClass={props.titleClass}
                    btnClass={props.btnClass}
                    moduleData={props.moduleData}
                    ref={textAnim}
                />
            </div>
        </section>
    );
}

// Check all the prop types
ImageTextModule.propTypes = {
    sectionClass: PropTypes.string,
    moduleClass: PropTypes.string,
    imageClass: PropTypes.string,
    textClass: PropTypes.string,
    Title: PropTypes.string,
    titleClass: PropTypes.string,
    btnClass: PropTypes.string,
    moduleData: PropTypes.array
};

// Specifies the default values for props:
ImageTextModule.defaultProps = {
    sectionClass: ' section-primary-linear-gradient',
    moduleClass: 'd-flex flex-column flex-lg-row align-items-lg-center',
    imageClass: '',
    textClass: ' pt-33 pb-40 px-25 pt-md-31 pb-md-40 px-md-60 py-xl-40 px-xl-96',
    Title: 'Let employers find you by uploading your CV.',
    titleClass: 'section-title h5 mb-25 mb-md-19 mb-xl-38',
    btnClass: 'btn btn-primary section-image-text__text__btn',
    moduleData: [
        {
            id: 1,
            imageUrl: 'young-woman.jpg',
            videoUrl: '',
            Title: 'Help & Advice',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            Buttons: [
                {
                    id: '123213',
                    Label: 'Upload Your CV',
                    externalLink: null,
                    page: null,
                    targetLink: '/candidates/upload-your-cv/'
                }
            ]
        }
    ]
};

export default ImageTextModule